.fc_item {
    width: 290px;
    margin: 0.4rem;
    position: relative;
}

.fc_item_image {
    height: 15rem;
}

.fc_image_overlay {
    background: rgba(250, 250, 250, 0.8);
    width: 100%;
    height: 15rem;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.fc_image_overlay_section {
    padding-bottom: 15px;
}

.fc_image_overlay_heading {
    margin: 0px 0px 10px 0px;
    font-size: 1rem;
    font-weight: 700;
}

.fc_hide {
    display: none;
}

.fc_image_overlay_point {
    margin: 0 !important;
    font-size: 0.8rem !important;
    margin: 0.2em 0 !important;
}

.fc_body {
    font-size: 1rem;
    flex: 1;
}

.fc_location {
    font-size: 0.8rem;
    margin: 0;
}

.fc_save {
    font-size: 0.8rem;
    margin: 0;
    margin-left: 0.4rem;
}

.fc_icon {
    font-size: 0.8rem;
}

.fc_container {
    display: flex;
    flex-direction: row;
    align-items: 'center';
}

.fc_location_container,
.fc_save_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: 'center';
}
