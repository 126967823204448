.header-container {
    display: flex !important;
    justify-content: space-between;
    .header-component {
        height: 100%;
        display: flex;
        // padding: 4px 16px;
        align-items: center;
        justify-content: space-between;
        //width: 100%;
        //max-width: 60rem;
        margin: 0.6rem 0;

        &__logo {
            flex: 1;
            max-width: 220px;
            min-width: 160px;
            cursor: pointer;

            img {
                width: 80%;
                object-fit: cover;
                // height: 100%;
                cursor: pointer;
            }
        }
        &__header-links {
            // margin-left: 16px;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            a {
                color: black;
                text-decoration: none;
                padding: 0 10px;
            }
        }

        &__header-actions {
            // flex: 1;
            display: flex;
            justify-content: flex-end;
            // margn

            button {
                margin: 0 10px;
            }
            a {
                text-decoration: none;
            }
            .loginbtn {
                background-color: #ff9e54;
                color: white;
            }
            // .signupbtn {
            //   background-color: #ff9e54;
            //   color: white;
            // }
        }
    }

    .header_right_side {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }

    .header_sidebar_btn {
        padding: 1rem 1rem;
    }

    .header_drawer_links {
        display: flex;
        flex-direction: column;
        width: 16rem;
        padding: 0.6rem;
    }

    .header_drawer_links button {
        margin: 0.1rem 0;
    }

    .header_sidebar_avatar {
        margin: 1rem auto 3rem auto;
    }

    .header_avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.2rem;
    }

    .hoverPointer:hover {
        cursor: pointer;
    }

    .search-results {
        overflow-y: auto;
    }
}
.nav-dropdown {
    text-transform: none !important;
    font-size: 1rem !important;
}
.MuiPopover-paper {
    top: 75px !important;
    background-color: #f3eee2 !important;
}

.MuiPopover-paper li a {
    text-decoration: none !important;
    color: black;
}
