.mi_wrap_content {
    flex-wrap: wrap;
}

.mi_smallOnMobile {
    width: 20rem;
}

.mi_textRow {
    display: flex;
    flex-direction: row;
    max-width: 32rem;
    margin: 0.6rem auto;
}

.mi_avatarContainer {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

// .mi_textRowBtn {
//   width: '20rem'
// }

.mi_searcharea_input {
    width: 100%;
}

.MuiInputBase-root {
    .MuiChip-root {
        background-color: #cb5f25 !important;
        .MuiChip-label {
            color: #fff !important;
            font-size: larger;
        }
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #cb5f25 !important;
    }
}

@media only screen and (max-width: 350px) {
    .mi_smallOnMobile {
        width: 14rem;
    }
}
