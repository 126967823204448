
.two_shaded_icon {
    filter: invert(35%) sepia(0%) saturate(3337%) hue-rotate(316deg) brightness(104%) contrast(77%);
}

// .width_fixer {
//     width: 45rem;
// }

.projects_list_cont {
    overflow-x: hidden;
}

.pl_createBtn {
    margin-left: 2rem !important;
}

@media only screen and (max-width: 800px) {
    .projects_list_in_cont {
        overflow-x: scroll;
        width: 90vw;
        margin: 0rem;
        padding: 0rem;
    }
}