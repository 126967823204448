@import 'OrganizationProfile.scss';

.section_width {
  width: 100%;
  max-width: 65rem;
}

.full_width {
  width: 100%;
}

// .g_profile_banner {
//   width: 160px;
// }