.donation_root {
    width: 100%;
    height: 100%;
    background-color: #135279;
}

.donation_main {
    padding: 2rem;
    background-color: white;
    margin: 3rem auto;
    max-width: 50%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.price_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 5px;
    place-items: center;
}

.input {
    border: 1px solid #cb6025;
    border-radius: 4px;
    padding: 12px 10px;
    transition: border-color 0.3s;
    margin-bottom: 15px;
}

.focused-input {
    border-color: #cb6025;
    border-width: 2px;
}

@media only screen and (max-width: 767px) {
    .donation_main {
        margin: 0;
        max-width: 100%;
    }
}
